<template>
  <div>
    <h3>October 22 2010 (v.1.0.8d)</h3>
    <p>
      <u>Property</u>
      <br>- Fixed a bug on private property, which would cause some to get kicked out of the game and lose a couple of minutes of progress.
    </p>
    <p></p>
    <h3>October 21 2010 (1.0.8)</h3>
    <p>
      <u>Front End</u>
      <br>- You will be redirected to a new LEGO Universe registration page when clicking the ‘Sign In’ button on the client login page.
      <br>- If you type the wrong password 3 times and thereby lock your account, the flow to unlock you account has been optimized.
    </p>
    <p>
      <u>Chat</u>
      <br>- The chat autosuggestions have been updated.
    </p>
    <p>
      <u>Quickbuilds</u>
      <br>- Fixed a bug that would cause certain quickbuild brick piles to disappear.
    </p>
    <p>
      <u>Missions</u>
      <br>- You are now rewarded every time you complete a Daily Faction Mission.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- Coalessa has got a new outfit.
    </p>
  </div>
</template>
